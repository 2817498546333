import { createRouter, createWebHistory } from 'vue-router'
import Home from './components/Pages/Home'
import Activity from './components/Pages/Activity'
import Company from './components/Pages/Company'
import Error404 from './components/Pages/Error404'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: "株式会社フロントラインR&D",
            description: "我々の持つ技術は自社のWebSiteではなくお付き合いのある皆様のために最大限注がれています。昨今のおかしな世の中を変えたい意欲のある方からの無理難題なシステム開発相談をいつも楽しみにお待ちしております。"
        }
    },
    {
        path: '/activity',
        name: 'Activity',
        component: Activity,
        meta: {
            title: "株式会社フロントラインR&D - Activity",
            description: "我々のもつ技術やサービスを列挙してみました"
        }
    },
    {
        path: '/company',
        name: 'Company',
        component: Company,
        meta: {
            title: "株式会社フロントラインR&D - Company",
            description: "会社概要 - 弊社設立当初から今に至るまで私たちは完全なリモートワークを続けています。オフィスにお越し頂いても何もありません。メールにてお問い合わせください。"
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: 'Error404',
        component: Error404,
        meta: {
            title: "株式会社フロントラインR&D - 404",
            description: "404",
            isErrorPage:true
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    document.querySelector("meta[name='description']").setAttribute('content', `${to.meta.description}`);

    if (to.meta.isErrorPage) {
        document.querySelector("meta[name='robots']").setAttribute('content', 'noindex,follow');
    } else {
        document.querySelector("meta[name='robots']").setAttribute('content', 'index,follow');
    }
    next();
});

export default router