<template>
  <ContentArea align="center">
    <div class="mx-3 mb-9">
      <img class="m-auto mb-5" src="../../assets/images/MainLogo.svg" />
      <img class="m-auto mb-4" src="../../assets/images/FrontLine.svg" />
      <img class="m-auto" src="../../assets/images/RD.svg" />
    </div>
    <p class="mb-3">
      我々の持つ技術は<br />自社のWebSiteではなく<br />
      お付き合いのある皆様のために最大限注がれています。
    </p>
    <p>
      昨今のおかしな世の中を<br />
      変えたい意欲のある方からの無理難題なシステム開発相談を<br />
      いつも楽しみにお待ちしております。
    </p>
  </ContentArea>
</template>

<script>
import ContentArea from "../ContentArea.vue";
export default {
  name: "PHome",
  components: { ContentArea },
};
</script>
