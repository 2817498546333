<template>
  <header class="fixed w-full">
    <div
      class="container mx-auto rounded-t-2xl"
      style="background-color: rgba(255, 255, 255, 0.7)"
    >
      <div class="container w-full flex items-center justify-between p-3">
        <div class="h-9">
          <router-link
            to="/"
            v-if="$route.path != '/'"
            class="flex items-center"
          >
            <img class="mr-3" src="../assets/images/HeaderLogo.svg" />
            <div class="hidden md:block">
              <img
                class="relative top-1"
                src="../assets/images/HeaderSub.svg"
              />
            </div>
          </router-link>
        </div>
        <div>
          <div class="">
            <router-link class="px-3" to="/">Home</router-link>
            <router-link class="px-3" to="/activity">Activity</router-link>
            <router-link class="px-3" to="/company">Company</router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<style scoped>
.px-3:hover {
  color: rgba(119, 51, 85, 0.5);
  transition: all 0.3s;
}
.router-link-active {
  color: #773355;
}
</style>