<template>
  <ContentArea align="center">
    <p>404 game over</p>
  </ContentArea>
</template>

<script>
import ContentArea from "../ContentArea.vue";
export default {
  name: "PError404",
  components: { ContentArea },
};
</script>
