<template>
  <ContentArea align="center">
    <div id="containerWidth">
      <div class="mb-7">
        <h1 class="tracking-wider text-2xl font-medium">Activity & Skill</h1>
        <p class="text-sm">我々の持つ技術やサービスを列挙してみました</p>
      </div>
      <div id="tagcloud" class="z-20 overflow-hidden">
        <ul>
          <li id="firstItem">
            <a href="https://www.kabuyu.net" target="_blank" rel="noopener">
              <img
                src="https://www.kabuyu.net/user_data/packages/kabuyu/img/banner/title.png"
                alt="株優.net"
                width="400"
                target="_blank"
                rel="noopener"
              />
            </a>
          </li>
          <li>SPA</li>
          <li>Web Application</li>
          <li>Crypto Currency Auto Trading System</li>
          <li>Forex Auto Trading System</li>
          <li>SEO Support</li>
          <li>Wordpress</li>
          <li>PIC</li>
          <li>Atmel</li>
          <li>アセンブラ</li>
          <li>VB</li>
          <li>VB.NET</li>
          <li>
            <a href="https://www.metakarte.com" target="_blank" rel="noopener">
              <img
                src="https://res.cloudinary.com/hkovjuyfr/image/upload/v1637668232/app/logo_no_shadow_nhfhsv.svg"
                alt="METAKARTE"
                width="400"
                target="_blank"
                rel="noopener"
              />
            </a>
          </li>
          <li>VBA（Excelマクロ）</li>
          <li>C#</li>
          <li>C++</li>
          <li>xaml</li>
          <li>F#</li>
          <li>C</li>
          <li>PHP</li>
          <li>javascript</li>
          <li>typescript</li>
          <li>TailWind</li>
          <li>css</li>
          <li>HTML</li>
          <li>Machine Janguage</li>
          <li>Laravel</li>
          <li>Inertia</li>
          <li>Vue.js</li>
          <li>UWSC</li>
          <li>MQL5</li>
          <li>電子機器製造</li>
          <li>電子機器修理</li>
        </ul>
      </div>
    </div>
  </ContentArea>
</template>

<script>
import ContentArea from "../ContentArea.vue";
/* eslint-disable no-undef */
export default {
  name: "PActivity",
  components: { ContentArea },
  data() {
    return {
      windowInnerWidth: window.innerWidth,
    };
  },
  computed: {
    containerWidth() {
      return document.getElementById("containerWidth").clientWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", this.setWindowInnerWidth);
    this.attachTagCloud();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setWindowInnerWidth);
  },
  methods: {
    setWindowInnerWidth() {
      this.windowInnerWidth = window.innerWidth;
      this.attachTagCloud();
    },
    attachTagCloud() {
      const settings = {
        //height of sphere 600
        height: window.innerWidth > 767 ? 527 : this.containerWidth,
        //width of sphere container
        width: window.innerWidth > 767 ? 527 : this.containerWidth,
        //radius of sphere
        radius: Math.min(this.windowInnerWidth / 3, 250),
        //rotation speed
        speed: 0.2,
        //sphere rotations slower
        slower: 0.01,
        //delay between up<a href="https://www.jqueryscript.net/time-clock/">date</a> position
        timer: 5,
        //dependence of a font size on axis Z
        fontMultiplier: 25,
        //tag css stylies on mouse over
        hoverStyle: {
          border: "none",
          color: "#0b2e6f",
        },
        //tag css stylies on mouse out
        mouseOutStyle: {
          border: "",
          color: "",
        },
      };

      $(document).ready(() => {
        $("#tagcloud").tagoSphere(settings);
        $("#firstItem").mouseover(); // これがないと発動しないので追加
      });
    },
  },
};
</script>
<style scoped>
li {
  white-space: nowrap;
}
</style>