<template>
  <div
    class="mx-3 tracking-wider text-sm md:text-lg max-w-full md:max-w-3xl"
    :align="align"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: "ContentArea",
  props: {
    align: {
      type: String,
      default: "left",
    },
  },
};
</script>