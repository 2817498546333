<template>
  <div class="bg-black h-screen">
    <Header class="z-10" />
    <!-- <div class="container mx-auto"> -->
    <div class="bg-white container mx-auto h-screen rounded-2xl">
      <div class="flex justify-center items-center h-full overflow-y-auto">
        <router-view />
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import Header from "./components/HeaderMenu.vue";

export default {
  name: "App",
  components: {
    Header,
  },
};
</script>

<style>
#app {
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN",
    "Hiragino Sans", Meiryo, sans-serif;
}
h1 {
  font-family: "Inter", sans-serif;
}
</style>
