<template>
  <ContentArea align="left">
    <h1 class="mb-28 text-center tracking-wider text-2xl font-medium">
      Company
    </h1>
    <table class="table-auto w-full mx-auto mb-16 leading-7 md:leading-10">
      <tr>
        <th class="w-32 md:w-60">社名</th>
        <td>株式会社フロントラインR&D</td>
      </tr>

      <tr>
        <th>設立</th>
        <td>2010年（平成22年）9月2日</td>
      </tr>
      <tr>
        <th>資本金</th>
        <td>300万円</td>
      </tr>
      <tr>
        <th>代表取締役</th>
        <td>廣野 隆司</td>
      </tr>
      <tr>
        <th>本社</th>
        <td>東京都渋谷区渋谷3丁目1番9号 Yazawaビル4階</td>
      </tr>
      <tr>
        <th>TEL</th>
        <td>050-5532-8321</td>
      </tr>
      <tr>
        <th>MAIL</th>
        <td>info@frontline-rd.com</td>
      </tr>
      <tr>
        <th>主要取引銀行</th>
        <td>みずほ銀行・楽天銀行</td>
      </tr>
    </table>
    <p>
      弊社設立当初から今に至るまで私たちは完全なリモートワークを続けています。<br />
      オフィスにお越し頂いても何もありません。メールにてお問い合わせください。
    </p>
  </ContentArea>
</template>

<script>
import ContentArea from "../ContentArea.vue";
export default { name: "PCompany", components: { ContentArea } };
</script>
<style scoped>
th {
  white-space: nowrap;
  font-weight: normal;
  padding-right: 1rem;
  text-align: left;
}
</style>